import React from "react"
import Cards from "../../components/body/pages/en-ng/cards"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const CardsPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/cards/"}
      title="Cards | Kuda | The Money App for Africans"
      description="You shouldn’t have to pay to own a debit card. We’ll send a free one to your address at no cost and never charge you a card maintenance fee."
    />
    <Cards />
  </Layout>
)

export default CardsPage
